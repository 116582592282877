@import ../../../../styles/helpers

.button
    width: 100%
    margin-top: 16px
    &:not(:last-child)
        margin-bottom: 8px

.title
    margin-bottom: 32px

.info
    margin-bottom: 16px
    +body-2
    color: $neutrals4
    a
        text-decoration: underline
        font-weight: 500
        color: $neutrals2
        +dark
            color: $neutrals8
        &:hover
            text-decoration: none

.line
    display: flex
    margin-bottom: 32px
    strong
        white-space: nowrap
        font-weight: 500

.details
    flex-grow: 1

.text
    color: $neutrals4

.link
    color: $neutrals5
    transition: color .25s ease
    &:hover
        color: $neutrals4
    +dark
        color: $neutrals4
        &:hover
            color: $neutrals5

.loaderSmall
    margin-right: 20px

.successInfo
    +body-bold-2
    color: $neutrals4
    margin-bottom: 20px
    span
        +caption-1
        color: $neutrals2
        +dark
            color: $neutrals8

.table
    padding: 24px
    border-radius: 12px
    border: 1px solid $neutrals6
    +dark
        border-color: $neutrals3

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-right: 5px
    padding-left: 5px
    padding-top: 10px
    padding-bottom: 10px
    font-size: 16px
    border-bottom: 1px solid $neutrals2

.row
    display: flex
    padding-right: 5px
    padding-left: 5px
    &:first-child
        padding: 0 0 12px
        border-bottom: 1px solid $neutrals6
        +dark
            border-color: $neutrals3

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500

.col
    text-overflow: ellipsis
    &:first-child
        margin-right: auto
        padding-right: 16px
    &:nth-child(2)
        text-align: right
        flex-shrink: 0
