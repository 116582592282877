// common styles
body
    min-width: 375px
    background: $neutrals8
    +poppins
    font-size: 14px
    line-height: calc(24/14)
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $neutrals2
    +dark-body
        background: $neutrals1
        color: $neutrals8

a
    text-decoration: none

.light-mode
    background: $neutrals1
    color: $neutrals8
    border-color: #23262F

.light-mode .section-bg
    background-color: #23262F

.light-mode div,
.light-mode footer
    border-color: #23262F

.light-mode footer div:nth-child(2),
.light-mode footer div:nth-child(3)
    border-color: #23262F

.light-mode header
    border-color: #23262F

svg,
img
    vertical-align: middle

div.extended
    max-width: 530px

.profile
    width: calc(90%)

.desktop
    &-hide
        +d
            display: none !important
    &-show
        display: none !important
        +d
            display: block !important
    &-text-right
        +d
            text-align: right

.tablet
    &-hide
        +t
            display: none !important
    &-show
        display: none !important
        +t
            display: block !important

.mobile
    &-hide
        +m
            display: none !important
    &-show
        display: none !important
        +m
            display: block !important
