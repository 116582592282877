@import ../../styles/helpers

.title
    margin-bottom: 32px
    padding-top: 16px
    text-align: center

.info
    max-width: 320px
    margin: 0 auto 16px
    text-align: center
    +body-bold-2
    span
        text-decoration: underline

.icon
    svg
        fill: $neutrals6

.table
    padding: 24px
    border-radius: 12px
    border: 1px solid $neutrals6
    +dark
        border-color: $neutrals3

.first
    width: 140px

.col
    color: $neutrals4
    span
        margin-left: 10px

.row
    display: flex
    width: 100%
    justify-content: space-between

.stage
    margin-bottom: 16px
    text-align: center
    +body-bold-2

.socials
    display: flex
    justify-content: center

.link
    color: $neutrals5
    transition: color .25s ease
    &:hover
        color: $neutrals4
    +dark
        color: $neutrals4
        &:hover
            color: $neutrals5

.social
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 36px
    height: 36px
    border: 2px solid $neutrals4
    border-radius: 50%
    cursor: pointer
    transition: border-color .2s
    +dark
        border-color: $white
    svg
        fill: $white
        transition: fill .2s
    &:hover
        border-color: $blue
        svg
            fill: $blue
    &:not(:last-child)
        margin-right: 16px
