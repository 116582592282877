@import ../../styles/helpers

.list
    display: flex
    flex-wrap: wrap
    height: 100vh
    margin-top: -32px
    // margin: -32px -16px 0
    +x
        // margin: -32px -10px 0
        margin: -32px 0px 0
    +d
        // margin: -32px -16px 0
        margin: -32px 0px 0
    +m
        display: block
        margin: 0

.card
    flex: 0 0 calc(33.333% - 27px)
    width: calc(33.333% - 27px)
    margin: 32px 12px 0
    +x
        flex: 0 0 calc(33.333% - 20px)
        width: calc(33.333% - 20px)
        margin: 32px 10px 0
    +d
        flex: 0 0 calc(50% - 32px)
        width: calc(50% - 32px)
        margin: 32px 16px 0
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px

.loader
    justify-content: center
    align-items: center
    display: flex
    flex-direction: column-reverse
    margin: 48px auto 10px
