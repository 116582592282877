@import ../../../styles/helpers

.title
    margin-bottom: 24px
    +m
        text-align: center

.flap
    margin-left: 3px !important
    margin-right: 3px !important

.flip
    transform: scaleY(-1)
    margin-left: 3px !important
    margin-right: 3px !important

.main
    margin-bottom: 16px
    +body-2
    color: $neutrals4

.planModal
    max-width: 900px

.extended
    max-width: 600px

.CSRModal
    width: 90%
    max-width: none !important

.list
    display: flex
    flex-wrap: wrap
    /*margin: 0 -16px*/
    /*border-width: 2px 0 1px*/
    /*border-style: solid*/
    border-color: $neutrals6
    +t
        display: block
        margin: 0
        padding: 48px 0
    +m
        padding: 0
        border: none
    +dark
        border-color: $neutrals3

.input
    text-align: right
    -moz-appearance: textfield
    padding: 0 5px
    width: 100%
    background: none
    font-size: 16px
    line-height: 1.5
    +poppins
    color: $neutrals2
    transition: border-color .2s
    +dark
        color: $neutrals8
    +placeholder
        color: $neutrals4

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type=number]
    -moz-appearance: textfield

.option
    width: 20%
    &:not(:last-child)
        margin-bottom: 8px

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-right: 5px
    padding-left: 5px
    padding-top: 10px
    padding-bottom: 10px
    font-size: 16px
    border-bottom: 1px solid $neutrals2

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px

.item2
    &.error
        .icon
            border-color: $pink
            background: transparent
            svg
                fill: $pink
    &.done
        .icon
            border-color: $green
            background: $green url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 50% / 14px auto
            svg
                display: none

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500


.item
    display: flex
    align-items: center
    flex: 0 0 calc(50% - 32px)
    width: calc(50% - 32px)
    margin: 40px 16px -1px
    padding-bottom: 48px
    border-bottom: 1px solid $neutrals6
    cursor: pointer
    +x
        cursor: default
    +t
        width: 100%
        margin: 0
        padding: 0
        border: none
    +m
        display: block
        text-align: center
    +dark
        border-color: $neutrals2
    &:not(:last-child)
        +t
            margin-bottom: 48px
        +m
            margin-bottom: 40px
    .lend
        position: absolute
        top: calc(100% + 6px)
        left: 143px
        visibility: hidden
        opacity: 0
        +x
            position: static
            margin-top: 24px
            visibility: visible
            opacity: 1
        +m
            margin-top: 16px
    .borrow
        // background: #9757D7
        position: absolute
        top: calc(100% + 6px)
        left: 0
        visibility: hidden
        opacity: 0
        +x
            position: static
            margin-top: 24px
            visibility: visible
            opacity: 1
        +m
            margin-top: 16px
    &:hover
        .details
            transform: translateY(-33px)
            +x
                transform: translateY(0)
        .borrow
            visibility: visible
            opacity: 1
        .lend
            visibility: visible
            opacity: 1
        & .tokenImage
            transform: scale(1.1)

.preview
    display: flex
    justify-content: left
    align-items: baseline
    flex-shrink: 0
    width: 160px
    height: 160px
    margin-right: 24px
    border-radius: 24px
    position: relative
    +m
        margin: 0 auto 16px
    background-image: url("../../../assets/images/loaders/golden.svg")
    background-repeat: no-repeat
    background-size: 30px
    background-position: center
    filter: hue-rotate(300deg)
    img
        filter: hue-rotate(60deg)
    .tokenLogo
        position: absolute
        width: 40px
        margin-top: -10px
        margin-left: -20px
        filter: brightness(0.9) contrast(1.5) hue-rotate(60deg)

.tokenImageContainer
    width: 100%
    height: 100%
    border-radius: 24px
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center

    .tokenImage
        height: 100%
        vertical-align: middle
        align-items: center
        transform: scale(1)
        transition: all 1s

.details
    position: relative
    transition: transform .2s

.status
    margin-bottom: 12px
    +m
        line-height: 22px

.risk
    margin-bottom: 12px
    margin-left: 5px
    +m
        line-height: 22px

.subtitle
    +body-bold-2

.description
    +caption-1
    color: $neutrals4

.btns
    margin-top: 80px
    text-align: center
    +t
        margin-top: 64px
    +m
        margin-top: 48px

.loader
    width: 100%
    height: 250px
    display: flex
    justify-content: center
    align-items: center

.sectionUserView
    display: flex
    flex-wrap: wrap
    padding-top: 16px

    .loader
        height: unset
        align-items: center
        display: flex
        flex-direction: column-reverse
        width: 100%
        margin-top: 77px
        margin-bottom: 60px

