@import ../../../styles/helpers

.list
    max-height: 232px

.total
    font-weight: 600
    padding-right: 10px

.normal
    filter: none

.label
    font-size: 15px
    color: gray
    line-height: 15px
    margin-top: 3px

.value
    font-size: 16px
    color: $neutrals6
    overflow: hidden
    text-overflow: ellipsis
    width: 110px

.grayscale
    filter: grayscale(1)

.info
    margin-bottom: 10px
    +body-2
    color: $neutrals4
    a
        text-decoration: underline
        font-weight: 500
        color: $neutrals2
        +dark
            color: $neutrals8
        &:hover
            text-decoration: none

.circle
    background: #c2c2c2
    padding: 9px
    border-radius: 30px
    width: 33px
    height: 33px
    display: flex
    justify-content: center
    filter: invert(1)
    opacity: 0.1

.active
    div
        filter: invert(0.03)
        opacity: 0.8
        background: #18181a
        svg
            fill: $neutrals6

.sum
    display: flex
    margin-top: 14px
    margin-bottom: 24px
    justify-content: space-between
    width: 100%

.starter
    display: flex
    justify-content: space-between
    height: 100px
    align-items: center
    width: 100%
    white-space: nowrap
    gap: 25px

.icon
    flex-shrink: 0
    width: 200px
    margin-right: 8px
    svg
        fill: $neutrals4
        margin-left: 5px
        margin-right: 5px
        transition: fill .2s

.item
    display: flex
    align-items: center
    justify-content: space-between
    height: 88px
    border-top: 1px solid $neutrals6
    +dark
        border-color: $neutrals3

.avatar
    position: relative
    flex-shrink: 0
    display: flex
    width: 48px
    height: 48px
    margin-right: 16px
    & > img
        width: 100%
        height: 100%
        margin-right: 10px
        border-radius: 50%
        object-fit: cover

.reward
    position: absolute
    right: -5px
    bottom: -5px
    img
        max-width: 25px

.tx
    padding-right: 10px
    color: $neutrals4
    cursor: pointer
    &:hover
        color: $neutrals8

.details
    flex-grow: 1

.name
    color: $neutrals4
    line-height: 18px
    margin-top: 2px
    +dark
        color: $neutrals5

.amount
    font-weight: 500
    color: $neutrals1
    +dark
        color: $neutrals8
