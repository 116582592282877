@import ../../../styles/helpers

.list
    display: flex
    flex-wrap: wrap
    align-content: baseline
    margin-bottom: -10px
    .collections
        flex-direction: column

.scrollbar
    width: 380px
    margin-bottom: 20px
    +m
        width: 290px
        overflow: scroll !important

.item
    width: 100%
    display: flex
    height: 136px
    +m
        position: relative
        padding: 0 100px 0 0
        border: none
        height: 100px
    &.tokens
        height: 145px
        +m            
            height: 105px
    +dark
        border-color: $neutrals3
    &:not(:last-child)
        border-bottom: 1px solid $neutrals3
        margin-bottom: 32px
        +m
            margin-bottom: 32px

.follower
    display: flex
    align-items: center
    flex: 0 0
    padding-right: 24px
    +d
        flex: 0 0
        pa
    +m
        width: 100%
        padding-right: 10px

.wrap
    width: 100%
    flex: 0 0 calc(100% - 326px)
    +d
        width: calc(100% - 244px)
        flex: 0 0 calc(100% - 244px)
    +m
        width: calc(100% - 244px)
        flex: 0 0 calc(100% - 244px)
        display: contents

.avatar
    flex-shrink: 0
    width: 118px
    height: 118px
    margin-right: 20px
    margin-top: -27px
    +m
        width: 72px
        height: 72px
        margin-right: 16px
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%

.galleryToken
    img
        transition: 0.7s
        opacity: 0.8
    &:hover
        img
            opacity: 1

.avatar,
.galleryToken
    overflow: hidden
    border-radius: 20%
    cursor: pointer
    img
        transition: 0.7s
    &:hover
        img
            transform: scale(1.1)

.title
    +body-bold-2

.counter
    +caption-2
    color: $neutrals4
    +m
        margin-bottom: 12px

.button
    height: 32px
    margin-top: 12px
    padding: 0 12px
    +m
        transform: translateY(-50%)
        font-size: 12px

.gallery
    display: flex
    position: absolute
    overflow: hidden
    overflow-x: auto
    -ms-overflow-style: none
    scrollbar-width: none
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
        display: none

.preview
    flex-shrink: 0
    width: 112px
    margin: 0 4px
    +m
        width: 72px
        height: 72px
    img
        width: 100%
        border-radius: 12px
        +m
            width: 100%
            height: 100%
    &.inactive
        filter: grayscale(0.5)
        opacity: 0.5
        cursor: pointer
        &:hover
            opacity: 0.8
            cursor: pointer


.loader
    justify-content: center
    align-items: center
    display: flex
    flex-direction: column-reverse
    width: 100%
    margin-top: 57px
    margin-bottom: 60px


.field
    margin: 20px 0 20px

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.emptyPlaceholder
    flex-direction: column
    display: flex
    justify-content: center
    align-items: center
    height: 300px
    width: 100%

.clickable
    cursor: pointer

.dropdownContainer
    display: flex
    align-items: center
    > div
        flex: 1
    .refresh
        margin-left: 10px
        flex-grow: 0