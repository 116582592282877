@import ../../styles/helpers

.title
    margin-bottom: 32px

.text
    margin-bottom: 16px
    +body-2
    color: $neutrals4

.loaderSmall
    margin-right: 20px

.inactive
    cursor: default
    filter: grayscale(1)
    color: gray

.table
    &:last-child
    margin-bottom: 20px

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-right: 5px
    padding-left: 5px
    padding-top: 10px
    padding-bottom: 10px
    font-size: 16px
    border-bottom: 1px solid $neutrals2

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500

.info
    margin-bottom: 3px
    +body-bold-1

.input
    text-align: right
    -moz-appearance: textfield
    padding: 0 5px
    width: 100%
    background: none
    font-size: 16px
    line-height: 1.5
    +poppins
    color: $neutrals2
    transition: border-color .2s
    +dark
        color: $neutrals8
    +placeholder
        color: $neutrals4

.btns
    margin-top: 32px

.prep
    margin-top: 8px
    padding-bottom: 16px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px
