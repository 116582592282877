@import ../../../styles/helpers

.list
    height: 252px

.total
    font-weight: 500
    padding-right: 10px

.info
    margin-bottom: 26px
    +body-2
    color: $neutrals4
    a
        text-decoration: underline
        font-weight: 500
        color: $neutrals2
        +dark
            color: $neutrals8
        &:hover
            text-decoration: none

.sum
    display: flex
    margin-top: 8px
    margin-bottom: 32px
    justify-content: space-between
    width: 100%

.normal
    filter: none

.grayscale
    filter: grayscale(1)
    opacity: 0.5

.box
    display: flex
    justify-content: center
    gap: 15px
    cursor: pointer

.count
    margin-left: 5px

.icon
    flex-shrink: 0
    width: 200px
    margin-right: 8px
    svg
        fill: $neutrals4
        margin-left: 5px
        margin-right: 5px
        transition: fill .2s

.item
    display: flex
    align-items: center
    height: 88px
    border-top: 1px solid $neutrals6
    +dark
        border-color: $neutrals3
    &:not(:last-child)
        margin-bottom: 16px

.avatar
    position: relative
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 16px
    & > img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.reward
    position: absolute
    right: -5px
    bottom: -5px
    img
        max-width: 25px

.tx
    padding-right: 10px
    color: $neutrals4
    cursor: pointer
    &:hover
        color: $neutrals8

.details
    flex-grow: 1
    align-content: center

.name
    color: $neutrals4
    line-height: 18px
    margin-top: 2px
    +dark
        color: $neutrals5

.amount
    font-weight: 500
    color: $neutrals1
    +dark
        color: $neutrals8



.empty
    width: 150px
    border-radius: 100px
    margin: 0 auto
