@import ../../styles/helpers

.head
    position: relative
    display: flex
    align-items: flex-end
    height: 100px
    // height: 120px
    // height: 180px
    padding: 32px 0
    background-repeat: no-repeat
    // background-position: 50% 50%
    filter: brightness(0.1) contrast(0.9) blur(0px) grayscale(0)
    background-size: cover
    overflow: hidden
    +t
        height: 0px
    +m
        display: none
    .container
        display: flex
        justify-content: flex-end
    &.active
        .btns
            opacity: 0
        .file
            opacity: 1
            visibility: visible

.btns
    transition: opacity .2s
    .button
        box-shadow: 0 0 0 2px $neutrals4 inset
        color: $neutrals8
        +m
            flex-grow: 1
            padding: 0 10px
        svg
            fill: $neutrals8
        &:hover
            box-shadow: 0 0 0 2px $blue inset
        &:not(:last-child)
            margin-right: 16px

.file
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    color: $neutrals8
    opacity: 0
    visibility: hidden
    transition: all .2s
    +m
        align-items: flex-start
        padding-top: 40px
    &:before,
    &:after
        content: ""
        position: absolute
    &:before
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: rgba($neutrals1, .6)
    &:after
        top: 8px
        left: 8px
        right: 8px
        bottom: 8px
        border: 2px dashed $neutrals6
        border-radius: 12px
    input
        position: absolute
        top: 0
        left: 0
        z-index: 3
        font-size: 1400px
        opacity: 0
    svg
        margin-bottom: 24px
        fill: $neutrals8
        +m
            width: 24px
            height: 24px
            margin-bottom: 12px
    .button
        position: absolute
        right: 16px
        bottom: 16px
        z-index: 4
        +t
            bottom: 48px
        +m
            right: 50%
            transform: translateX(50%)

.wrap
    position: relative
    z-index: 2

.info
    font-size: 24px
    line-height: calc(32/24)
    font-weight: 600
    +m
        font-size: 16px

.text
    +body-2
    +m
        font-size: 12px

.body
    position: relative
    z-index: 3
    padding: 40px 0
    +t
        padding-top: 0
    +m
        padding: 0 0 64px
    .container
        display: flex
        align-items: flex-start
        +t
            display: block

.user
    flex-shrink: 0
    width: 248px
    margin-top: -93px
    +t
        width: calc( 100% + 80px )
        margin: -20px -40px 48px -40px
    +m
        width: calc( 100% + 64px )
        margin: 15px -32px 48px -32px

.wrapper
    flex: 0 0 calc(100% - 128px)
    width: calc(100% - 128px)
    padding-left: 48px
    +x
        padding-left: 32px
    +t
        width: 100%
        padding-left: 0

.nav
    display: flex
    margin: 0 0px 32px
    +m
        margin: 0 -32px 32px
        justify-content: space-between
        overflow: auto
        overflow-x: auto
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none
        &:before,
        &:after
            content: ""
            flex-shrink: 0
            width: 32px
            height: 1px

.link
    flex-shrink: 0
    margin: 0 12px
    padding: 6px 18px
    margin-left: 0px
    margin-right: 20px
    border-radius: 16px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
        padding: 6px 10px
    &:hover
        color: $neutrals3
        svg
            fill: $neutrals6
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        svg
            fill: $neutrals2
        +dark
            background: $neutrals8
            color: $neutrals2
    svg
        fill: $neutrals4
        margin-right: 4px
        margin-top: -1px
        +m
            display: none

.following,
.followers
    padding-top: 16px
    +m
        padding-top: 0
