.tooltip
    position: relative
    cursor: pointer
    &.right
        &::before
            content: attr(data-text)
            position: absolute
            top: 50%
            left: 35px
            transform: translate(0, -50%)
            margin-left: auto
            margin-right: auto
            padding: 0.5rem 1rem
            border-radius: 10px
            background: #000
            color: white
            text-align: center
            opacity: 0
            transition: .3s opacity
            width: max-content
    &.right
        &::after
            opacity: 0
            content: ""
            position: absolute
            border: 10px solid #000
            display: block
            transition: .3s opacity
            left: 15px
            border-color: transparent black transparent  transparent
    &.top
        &::before
            content: attr(data-text)
            position: absolute
            top: -50px
            left: 50%
            transform: translate(-50%, 0)
            margin-top: auto
            padding: 0.5rem 1rem
            border-radius: 10px
            background: #000
            color: white
            text-align: center
            opacity: 0
            transition: .3s opacity
            width: max-content
    &.top
        &::after
            opacity: 0
            content: ""
            position: absolute
            border: 10px solid #000
            display: block
            transition: .3s opacity
            top: -15px
            border-color: black transparent transparent  transparent
    &.bottom
        &::before
            content: attr(data-text)
            position: absolute
            top: 30px
            left: 50%
            transform: translate(-50%, 0)
            margin-top: auto
            padding: 0.5rem 1rem
            border-radius: 10px
            background: #000
            color: white
            text-align: center
            opacity: 0
            transition: .3s opacity
            width: max-content
    &.bottom
        &::after
            opacity: 0
            content: ""
            position: absolute
            border: 10px solid #000
            display: block
            transition: .3s opacity
            top: 15px
            border-color: transparent transparent black transparent
    &:hover
        &::before, &::after
            opacity: 1