@import "../../styles/helpers"

.tree
    color: $neutrals4

.item
    padding-bottom: 10px
    padding-top: 10px
    border-bottom: 1px solid $neutrals3
    &.expandable:hover
        color: $neutrals8
        cursor: pointer
    .toggle
        color: $green
        padding-right: 5px
        cursor: pointer
    .warning
        color: $orange
