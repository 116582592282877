@import ../../styles/helpers

.page
    padding: 100px 0
    p
        font-size: 16px
        &:not(:last-child)
        margin-bottom: 10px

.create-holder
    margin-top: 50px
    margin-bottom: 50px
    div
        margin-top: 20px
        margin-bottom: 20px
