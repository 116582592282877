@import ../../styles/helpers

.page
    padding: 100px 0
    p
        font-size: 16px
        &:not(:last-child)
            margin-bottom: 10px

.row
    display: flex

.col
    flex: 1

.list 
    flex: unset
    flex-shrink: 0
