@import ../../styles/helpers

.title
    margin-bottom: 32px

.text
    +body-2
    color: $neutrals4

.pink
    color: $pink

.divider
    border-bottom: 1px solid $neutrals2
    padding-bottom: 16px
    margin-bottom: 16px

.bottomSpace
    margin-bottom: 16px

.card
    position: relative
    border-radius: 25px
    overflow: hidden
    transition: 0.7s
    & > img
        width: 100%
    &:hover
        opacity: 1
        filter: none
        transition: 0.7s
        .img
            opacity: 1
            transition: 0.7s
            transform: scale(1.1)
    &:hover
        .control
            visibility: visible
            background: none
            opacity: 1

.slideContainer
    width: 100%
    margin-top: 16px
    .slider
        height: auto

.control
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    visibility: hidden
    border-radius: 25px
    opacity: 0
    transition: all .2s

.button
    position: absolute
    left: 50%
    bottom: 16px
    transform: translateX(-50%)

.footer
    display: flex
    flex-direction: row
    justify-content: space-around
    margin-top: 32px
    padding: 0 8px
    +m
        display: block
    & > a
        filter: grayscale(1)
        font-size: 12px
        line-height: 0px
        padding: 15px
        font-weight: normal
        border-radius: 12px
        color: $neutrals8
        +m
            display: block
            width: 100%
            margin-bottom: 10px
            text-align: center
        &:hover
            filter: grayscale(0)
        &.website
            background-image: linear-gradient(135deg,$purple 10%,$pink)
        &.collection
            background-image: linear-gradient(140deg,$purple 10%,$pink)
        &.discord
            background-image: linear-gradient(145deg,$purple 10%,$pink)
