@import ../../styles/helpers

.processing
    color: $white
    margin-top: 10px
    width: 100%
    display: flex
    padding-left: 10px    

.error
    color: $yellow
    margin-top: 10px
    width: 100%
    display: flex
    padding-left: 10px

.success
    color: $green
    margin-top: 10px
    width: 100%
    display: flex
    padding-left: 10px
