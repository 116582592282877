@import ../../styles/helpers

.page
    padding-top: 105px
    overflow: hidden
    +m
        padding-top: 100px

.withBanner,
.airdropBanner
    padding-top: 150px

.banner,
.drop
    display: flex
    position: fixed
    top: 105px
    z-index: 4
    justify-content: center
    align-items: center
    width: 100%
    padding: 10px
    background: #c73d77
    +m
        top: 137px

.banner
    background: linear-gradient(-45deg, #c73d77, #c73d77, #902995, #A92274)
    background-size: 400% 400%
    color: white
    animation: gradient 15s ease infinite

    a:link,
    a:visited
        text-decoration: inherit
        color: inherit
        cursor: pointer

    .close
        position: absolute
        right: 20px
        width: 20px
        height: 20px
        margin-left: auto
        border: 1px solid $neutrals4
        border-radius: 50%
        font-size: 0
        svg
            fill: $neutrals2
            transition: transform .2s
        +dark
            border-color: $neutrals8
            background: transparent
            svg
                fill: $neutrals8
        &:hover
            svg
                transform: rotate(90deg)

@keyframes gradient
    0% 
        background-position: 0% 50%
    50%
        background-position: 100% 50%
    100%
        background-position: 0% 50%
