@import ../../../styles/helpers

.options
    display: flex
    padding: 8px
    border-radius: 32px
    background: $neutrals8
    +dark
        background: $neutrals2

.main
    margin-left: 24px
    margin-right: 24px

.open
    transform: rotate(-45deg)
