@import ../../styles/helpers

.container
    display: flex
    flex-direction: row-reverse
    +t
        flex-direction: column
        margin-bottom: 64px

.section
    position: relative
    display: flex
    align-items: center
    min-height: 600px
    margin-bottom: 0px
    padding: 100px 0
    +d
        min-height: 600px
        padding: 0
    +t
        display: block
        min-height: auto
        padding: 32px 0 0
        padding-top: 32px

.btns
    display: flex
    +m
        flex-direction: column
        gap: 16px
        a
            width: 100%

.button
    +m
        padding: 0 22px
    &:not(:last-child)
        margin-right: 16px

.wrap
    position: relative
    z-index: 3
    max-width: 450px
    +t
        max-width: 100%

.title
    margin-bottom: 32px

.text
    margin-bottom: 40px
    +body-1
    color: $neutrals4

.gallery
    position: absolute
    top: 53%
    left: calc(50% - 541px)
    width: 584px
    transform: translateY(-40%)
    pointer-events: none
    +d
        right: calc(50% - 440px)
        transform: translateY(-44%)
    +t
        position: relative
        top: auto
        right: auto
        left: -15px
        margin: 96px auto 24px
        transform: translateY(0)
        margin-bottom: 0px
        margin-top: 80px
        width: 100%

.preview
    &:first-child
        position: relative
        z-index: 2
        width: 125%
        margin-top: -150px
        +t
            height: 400px
            width: 100%
        +m
            height: auto
    &:nth-child(2)
        top: -27%
        left: -10%
        z-index: 1
        width: 50%
        +t
            top: -200%
        +m
            top: -70%
    &:nth-child(3)
        top: -18%
        right: 31%
        z-index: 1
        width: 33%
    &:not(:first-child)
        position: absolute
    img
        width: 100%
