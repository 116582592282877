[class^="section"]
    padding: 64px 0
    +x
        padding: 64px 0
    +m
        padding: 64px 0

.section-bg
    background: $neutrals7
    +dark-common
        background: $neutrals2

.section-pba
    padding-top: 100px
    +d
        padding-top: 0
    +m
        padding-top: 0

.section-pb
    padding-top: 0
    +d
        padding-top: 0
    +m
        padding-top: 0

.section-pt80
    padding-top: 80px
    +d
        padding-top: 64px
    +m
        padding-top: 32px
