@import ../../styles/helpers

.title
    margin-bottom: 24px

.pink
    color: $green

.noToken
    margin-top: -70px
    display: flex
    justify-content: center
    width: 100%

.line
    display: flex
    margin-bottom: 32px
    strong
        white-space: nowrap
        font-weight: 500

.main
    margin-bottom: 16px
    +body-2
    color: $neutrals4

.icon
    position: relative
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 48px
    height: 48px
    background: $neutrals6
    border: 2px solid $neutrals6
    border-radius: 50%
    transition: all .2s
    margin-right: 15px
    svg
        fill: $neutrals6
        transition: fill .2s
    +dark
        background: $neutrals3
        border-color: $neutrals3
    .loader
        position: absolute
        top: -2px
        left: -2px
        right: -2px
        bottom: -2px

.details
    margin-left: 5px
    flex-grow: 1

.info
    +body-bold-2

.switch
    flex-shrink: 0
    margin-left: 16px

.body
    margin-bottom: 16px
    margin-top: 24px
    +body-2
    color: $neutrals4

.border
    border-top: 1px solid $neutrals2
    align-items: center
    display: flex
    width: 100%
    justify-content: center
    height: 50px

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-top: 10px
    padding-bottom: 10px
    font-size: 16px
    border-bottom: 1px solid $neutrals2
    white-space: nowrap

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500

.btns
    margin-top: 32px

.option
    width: 20%
    &:not(:last-child)
        margin-bottom: 8px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px

.item
    &.error
        .icon
            border-color: $pink
            background: transparent
            svg
                fill: $pink
    &.done
        .icon
            border-color: $green
            background: $green url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='none' viewBox='0 0 14 10'%3E%3Cpath fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/%3E%3C/svg%3E") no-repeat 50% 50% / 14px auto
            svg
                display: none

.head
    display: flex
    align-items: center

.text
    +caption-2
    color: $neutrals4

.note
    margin-top: 16px
    +caption-2
    color: $neutrals4
    a
        color: $blue
        &:hover
            text-decoration: underline

.input
    text-align: right
    -moz-appearance: textfield
    padding: 0 5px
    width: 100%
    background: none
    font-size: 16px
    line-height: 1.5
    +poppins
    color: $neutrals2
    transition: border-color .2s
    +dark
        color: $neutrals8
    +placeholder
        color: $neutrals4

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type=number]
    -moz-appearance: textfield

.loaderSmall
    margin-right: 20px

.stepLine
    height: 30px
    width: 1px
    border-left: 1px solid $neutrals4
    margin: 8px 0px 12px 24px

.inputMessage
    font-size: 12px
    padding-left: 10px
    position: relative
    color: $neutrals4

.collections
    display: flex
    justify-content: center
    align-items: center
    height: 88px
    margin-bottom: 48px
    +m
        // width: 72px
        height: 72px
