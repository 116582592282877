@import ../../styles/helpers

.category
    color: $neutrals6

.rotate
    display: inline-flex
    align-items: center
    +button-2
    margin-left: 15px
    cursor: pointer
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals2
        transform: rotate(45deg)
        transition: fill .2s
        +dark
            fill: $neutrals8
    &:hover
        color: $blue
        svg
            fill: $blue

.title
    margin-bottom: 32px

.info
    margin-bottom: 32px
    +body-2
    strong
        font-weight: 500

.main
    margin-bottom: 24px
    +body-2
    color: $neutrals4

.body
    margin-bottom: 16px
    margin-top: 24px
    +body-2
    color: $neutrals4

.row
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    padding: 20px
    font-size: 16px
    border: 1px solid $neutrals2
    border-radius: 12px

.roller
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%
    padding: 20px
    font-size: 16px
    border: 1px solid $neutrals2
    border-radius: 12px

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500
