@import ../../styles/helpers

.tip
    flex-shrink: 0
    margin-left: 0px
    padding: 0 8px
    border-radius: 4px
    box-shadow: inset 0 0 0 2px $green
    font-size: 12px
    line-height: 26px
    font-weight: 700
    white-space: nowrap
    text-transform: uppercase
    color: $green
    opacity: 0.75
    &:hover
        opacity: 1

.apy
    flex-shrink: 0
    white-space: nowrap
    margin-left: 0px
    padding: 0 8px
    border-radius: 4px
    box-shadow: inset 0 0 0 2px $green
    font-size: 12px
    line-height: 26px
    font-weight: 700
    text-transform: uppercase
    color: $green
    opacity: 0.75
    &:hover
        opacity: 1
