@import ../../styles/helpers

.title
    margin-bottom: 24px

.text
    margin-bottom: 16px
    +body-2
    color: $neutrals4

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-right: 5px
    padding-left: 5px
    padding-top: 10px
    padding-bottom: 10px
    font-size: 16px
    border-bottom: 1px solid $neutrals2

.avatar
    position: relative
    flex-shrink: 0
    width: 48px
    height: 48px
    margin-right: 16px
    & > img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover

.info
    margin-bottom: 3px
    +body-bold-1

.input
    width: 100%
    height: 48px
    border-bottom: 1px solid $neutrals6
    +poppins
    +body-bold-2
    color: $neutrals2
    +placeholder
        color: $neutrals4

.btns
    margin-top: 32px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px
