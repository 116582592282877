@import ../../../styles/helpers

.collectionSelector
    border: 2px solid transparent
    cursor: pointer
    margin-bottom: 20px
    // min-height: 175px

.rotate
    display: inline-flex
    align-items: center
    +button-2
    margin-left: 15px
    cursor: pointer
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals2
        transform: rotate(45deg)
        transition: fill .2s
        +dark
            fill: $neutrals8
    &:hover
        color: $blue
        svg
            fill: $blue

.link
    flex-shrink: 0
    // min-width: 150px
    margin: 0 0px
    margin-right: 6px
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2
    &:not(:last-child)
        +m
            margin-right: 12px
