@import ../../styles/helpers

a
    svg
        fill: $neutrals4
        padding-bottom: 2px
    &:hover
        svg
            fill: $neutrals6

.icon
    margin-left: 5px
    font-weight: bold
    font-size: 20px

.main
    margin: 5px 5px 16px
    +body-2
    color: $neutrals4

.source
    display: flex
    justify-content: center
    margin-bottom: 0px

.loader
    display: flex
    justify-content: center
    align-items: center
    height: 200px

.title
    margin-top: 0px
    margin-bottom: 24px

.field,
.checkbox
    margin: 16px 16px 0 0

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5
