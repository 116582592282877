@import ../../styles/helpers

.section
    text-align: center
    .container
        max-width: 896px

.top
    max-width: 736px
    margin: 0 auto 40px
    +d
        margin-bottom: 64px
    +m
        margin-bottom: 32px

.title
    margin-bottom: 16px

.info
    color: $neutrals4
    margin-top: 40px
    span
        color: $neutrals2
        +dark
            color: $neutrals8

.list
    display: flex
    margin: 0 -16px 32px
    +m
        display: block
        margin: 0 0 32px

.item
    flex: 0 0 calc(50% - 32px)
    width: calc(50% - 32px)
    margin: 0 16px
    padding: 16px 16px 24px
    border-radius: 25px
    border: 1px solid $neutrals6
    transition: box-shadow .2s
    +m
        width: 100%
        margin: 0
    +dark
        border-color: $neutrals3
    &:not(:last-child)
        +m
            margin-bottom: 24px
    &:hover
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12)
        .button
            background: $blue
            box-shadow: 0 0 0 2px $blue inset
            color: $neutrals8
            svg
                fill: $neutrals8

.preview
    margin-bottom: 24px
    img
        width: 100%
        border-radius: 8px

.note
    +caption-2
    color: $neutrals4
