@import ../../styles/helpers

.head
    max-width: 580px
    margin: 0 auto 80px
    text-align: center
    +d
        margin-bottom: 64px
    +m
        text-align: left

.stage
    margin-bottom: 8px
    color: $neutrals4

.list
    margin-bottom: 30px
    display: flex
    +m
        display: block

.item
    flex: 0 0 31%
    padding: 34px 83px
    +x
        padding: 34px 64px
    +d
        padding: 32px
    +m
        padding: 0
        text-align: center
    &:first-child
        padding-left: 0
    &:nth-child(2)
        flex: 0 0 38%
        border-width: 0 1px
        border-style: solid
        border-color: $neutrals6
        +m
            border: none
        +dark
            border-color: $neutrals3
    &:last-child
        padding-right: 0
    &:not(:last-child)
        +m
            margin-bottom: 24px
            padding-bottom: 24px
            border-bottom: 1px solid $neutrals6
            +dark
                border-color: $neutrals3

.icon
    margin-bottom: 32px
    width: 100px
    height: 20px
    transform: scale(1.5)
    margin-left: 20px
    svg
        +dark
            fill: $neutrals8
    +m
        margin: 0 auto 32px

.category
    margin-bottom: 32px
    font-weight: 600

.content
    +caption-1
    color: $neutrals4

.title
    margin-top: 90px
    &:after
        content: 'Here are the detailed features of Instant Pools'
    +m
        margin-top: 64px
        &:after
            content: 'Here are the detailed features on Instant Pools'
