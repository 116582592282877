@import ../../styles/helpers

.container 
    width: 100%
    height: 100%
    align-items: center
    justify-content: center
    text-align: center

.row 
    margin: 12px
    // font-weight: 700

.button 
    height: 32px
    padding: 0 12px
