@import ../../styles/helpers

.img
    transition: all 1s
    transform: scale(1.0)

.card
    cursor: pointer
    display: flex
    flex-direction: column
    +dark
    &:hover
        .img
            transform: scale(1.1)
    &.control.active
        opacity: 1
        visibility: visible
    &:hover
        .control
            visibility: visible
            background: none
            opacity: 1


.preview
    position: relative
    border-radius: 25px
    overflow: hidden
    & > img
        width: 100%

.control
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    visibility: hidden
    border-radius: 25px
    opacity: 0
    transition: all .2s
.control.active
    opacity: 1
    visibility: visible
    svg
        width: 14px
        height: 14px
        path
            fill: #ff3086

.category
    position: absolute
    top: 11px
    left: 8px

.favorite
    position: absolute
    top: 8px
    right: 8px
    width: 32px
    height: 32px
    background: $neutrals8
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2)
    border-radius: 50%
    svg
        width: 20px
        height: 20px
        fill: $neutrals4
        transition: fill .2s
        transition: opacity .2s
    &:hover
        svg
            fill: $neutrals2
    +dark
        background: $neutrals2
        svg
            fill: $neutrals8
        &:hover
            svg
                fill: $neutrals8

.button
    position: absolute
    left: 50%
    bottom: 16px
    transform: translateX(-50%)

.link
    display: flex
    flex-direction: column
    flex-grow: 1
    padding: 10px 0
    color: $neutrals2
    +dark
        color: $neutrals8

.body
    display: flex
    flex-direction: column
    flex-grow: 1

.line
    display: flex
    align-items: flex-start
    &:first-child
        margin-bottom: 12px
    &:nth-child(2)
        margin-top: auto

.title
    margin-right: auto
    padding-top: 1px
    +body-bold-3

.price
    flex-shrink: 0
    margin-left: 8px
    padding: 0 8px
    border-radius: 4px
    box-shadow: inset 0 0 0 2px $green
    font-size: 12px
    line-height: 26px
    font-weight: 700
    text-transform: uppercase
    color: $green

.users
    display: flex
    margin-right: auto

.avatar
    flex-shrink: 0
    width: 24px
    height: 24px
    border-radius: 50%
    border: 2px solid $neutrals8
    +dark
        border-color: $neutrals2
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%
    &:not(:first-child)
        margin-left: -8px

.counter
    +dark
        color: $neutrals6

.foot
    display: block
    align-items: center
    justify-content: space-between
    margin-top: 12px
    padding-top: 12px
    border-top: 1px solid $neutrals6
    +caption-2
    color: $neutrals4
    +dark
        border-color: $neutrals3

.status
    display: flex
    font-size: 14px
    justify-content: space-between
    width: 100%
    svg
        margin: -2px 4px 0 0
        fill: $neutrals4
    span
        font-weight: 600
        color: $neutrals3
        +dark
            color: $neutrals8
