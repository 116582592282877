@import ../../styles/helpers

.containerMo
    margin-top: 40px
    margin-bottom: 100px
    p
        font-size: 16px
        &:not(:last-child)
            margin-bottom: 10px
    .content
        display: flex
        gap: 20px
        justifyContent: center
        +t
            flex-direction: column
            gap: 0

.form
    height: 100%
    +t
        margin-bottom: 24px

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.checkbox
    margin: 16px 16px 0 0

.title
    margin-bottom: 0px

.fieldset
    width: 100%
    display: flex
    flex-direction: column
    margin-top: 32px
    margin-bottom: 32px
    .field
        &:not(:last-child)
            margin-bottom: 32px
            +m
                margin-bottom: 20px

.loaderSmall
    margin-right: 20px

.button
    width: 100%
    margin-top: 10px
    +m
        margin-bottom: 25px
        margin-top: 0px
        
.requestButton
    margin-top: 25px

.column
    flex: 1 1 0
    margin-bottom: 25px

.box
    display: flex
    width: 100%
    max-width: 800px
    max-height: 1000px
    box-sizing: border-box
    background: $neutrals8
    box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.12)
    border-radius: 25px
    padding: 24px
    &:not(:last-child)
        margin-bottom: 24px
    +dark
        background: $neutrals1
        box-shadow: inset 0 0 0 1px $neutrals3
    >div
        width: 100%
        overflow-wrap: break-word

.col
    flex: 1
    text-overflow: ellipsis

.treeLabel
    color: $neutrals8
    &::after
        content: ":"
.treeValue
    color: $neutrals4
    padding-left: 5px

.imgContainer
    position: relative
    border-radius: 25px
    overflow: hidden
    transition: 0.7s
    &:hover
        .img
            transform: scale(1.1)

.img
    transition: all 1s
    transform: scale(1.0)
    width: 100%

.tokenImage
    position: absolute
    top: 0px
    left: 0px
    border-radius: 25px
    width: 100%

.loader
    width: 100%
    transform: scale(0.2)
    filter: hue-rotate(300deg)
    position: relative


.page
    padding: 100px 0
    p
        font-size: 16px
        &:not(:last-child)
        margin-bottom: 10px

.boxer
    display: flex
    width: 100%
    justify-content: space-around
    button
        width: 45%

.extended
    max-width: 900px

.card
    display: flex
    flex-direction: column

.card
    flex: 0 0 calc(33.333% - 24px)
    max-width: calc(33.333% - 24px)
    margin: 0px 3px 0
    padding: 10px
    box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12)
    border-radius: 20px
    margin-bottom: 27px
    // width: 100%
    height: 250px
    +r(1279)
        flex: 0 0 calc(50% - 32px)
        max-width: calc(50% - 32px)
        height: 640px
    +m
        max-width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px
    +dark
        background: $neutrals2

.section
    +x
        padding-top: 40px
    +m
        padding-top: 32px

.pools
    display: flex
    gap: 30px

.head
    display: flex
    align-items: center
    margin-bottom: 0px
    +d
        flex-direction: column-reverse
        align-items: flex-start
    +m
        margin-bottom: 2px
    .button
        flex-shrink: 0
        margin-left: 24px
        +d
            margin: 0 0 16px

.title
    margin-right: auto
    font-size: 32px


.action-holder
    display: flex
    justify-content: space-between
    align-items: center

.action-holder > div
    width: 30%

.flex-box
    display: flex
    align-items: center
    gap: 30px

.info
    margin-bottom: 50px
    div
        margin-top: 5px
        margin-bottom: 20px

.top
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 24px
    margin-bottom: 2px
    width: 100%
    border-bottom: 1px solid $neutrals6
    +t
        display: block
        margin-bottom: 32px
        padding-bottom: 0
        border: none
    +m
        margin-bottom: 24px
    +dark
        border-color: $neutrals3

.slogan
    margin-top: 0px
    +body-2
    color: $neutrals4
    +m
        margin-bottom: 25px !important

.actions
    display: flex
    justify-content: space-between
    align-items: center

.actions > div
    width: 30%

.details
    padding-left: 15px
    padding-right: 15px
    padding-top: 10px

.box
    display: flex
    align-items: center
    gap: 30px

h1
    text-transform: capitalize

.sorting
    display: flex
    align-items: center
    +t
        display: block
    +m
        margin-bottom: 32px
    .dropdown
        width: 256px
        margin-right: auto
        +t
            width: 100%
            margin: 0 0 32px

.nav
    display: flex
    +m
        margin: 0 -32px
        overflow: auto
        overflow-x: auto
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none
        &:before,
        &:after
            content: ""
            flex-shrink: 0
            width: 32px
            height: 1px

.link
    flex-shrink: 0
    margin: 0 6px
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2
    &:not(:last-child)
        +m
            margin-right: 12px
