@import ../../styles/helpers

.section
    padding: 96px 0
    +x
        padding: 80px 0
    +m
        padding: 64px 0

.sorting
    display: flex
    align-items: center
    +t
        display: block
    +m
        margin-bottom: 32px
    .dropdown
        width: 256px
        margin-right: auto
        +t
            width: 100%
            margin: 0 0 32px

.rotate
    display: inline-flex
    align-items: center
    +button-2
    margin-left: 15px
    cursor: pointer
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals2
        transform: rotate(45deg)
        transition: fill .2s
        +dark
            fill: $neutrals8
    &:hover
        color: $blue
        svg
            fill: $blue

.top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 24px
    padding-bottom: 26px
    width: 100%
    border-bottom: 1px solid $neutrals6
    padding-left: 10px
    padding-right: 10px
    +t
        display: block
        margin-bottom: 32px
        padding-bottom: 0
        border: none
    +m
        margin-bottom: 24px
    +dark
        border-color: $neutrals3

.head
    display: flex
    align-items: center
    margin-bottom: 20px
    +d
        flex-direction: column-reverse
        align-items: flex-start
    +m
        margin-bottom: 2px
    .button
        flex-shrink: 0
        margin-left: 24px
        +d
            margin: 0 0 16px

.slogan
    margin-top: 0px
    +body-2
    color: $neutrals4
    +m
        margin-bottom: 25px !important

.title
    margin-right: auto
    font-size: 32px

.container
    display: flex
    align-items: flex-start
    +t
        display: block

.bg
    position: relative
    flex-grow: 1
    margin-right: 64px
    margin-bottom: 64px
    +x
        margin-right: 64px
    +d
        margin-right: 32px
    +t
        margin: 0 0 32px

.details
    display: flex
    flex-direction: column
    flex-shrink: 0
    width: 550px
    +x
        margin-right: 484px
    +d
        width: 336px
    +t
        width: 100%

.preview
    display: flex
    justify-content: center
    justify-content: flex-start

.pink
    background-color: #c73e77

.tags
    margin-left: 15px

.categories
    position: absolute
    top: 16px
    display: flex
    flex-wrap: wrap
    z-index: 2
    justify-content: space-between
    width: 100%

.category
    margin: 8px 0 0 8px
    &.selected
        background-color: $green

.options
    position: absolute
    top: auto
    left: 50%
    bottom: 24px
    transform: translateX(-50%)

.name
    margin-bottom: 8px

.cost
    display: flex
    align-items: center
    margin-bottom: 24px

.price
    +dm-sans
    font-size: 16px
    line-height: 30px
    margin-right: 8px
    margin-left: 0px

.counter
    +button-1
    color: $neutrals4

.info
    margin-bottom: 26px
    +body-2
    color: $neutrals4
    a
        text-decoration: underline
        font-weight: 500
        color: $neutrals2
        +dark
            color: $neutrals8
        &:hover
            text-decoration: none

.nav
    display: flex
    justify-content: space-between
    margin-bottom: 12px
    padding: 6px
    border-radius: 20px
    box-shadow: inset 0 0 0 2px $neutrals6
    +dark
        box-shadow: inset 0 0 0 2px $neutrals3

.link
    flex-shrink: 0
    margin: 0 6px
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2
    &:not(:last-child)
        +m
            margin-right: 12px


.users
    margin-bottom: auto

.control
    margin-top: 10px
    height: 60px
    +m
        margin-top: 32px

.open
    transform: rotate(-45deg)

.external
    top: 0px
    width: 40px
    height: 40px
    background: $neutrals8
    box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.2)
    border-radius: 50%
    margin-right: 20px
    svg
        width: 20px
        height: 20px
        fill: $neutrals4
        transition: fill .2s
        transition: opacity .2s
    &:before
        content: ""
        position: absolute
        top: 51%
        left: 50%
        transform: translate(-50%,-50%)
        width: 18px
        height: 18px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='18' fill='none' viewBox='0 0 22 18'%3E%3Cpath d='M11 1.81A6.48 6.48 0 0 0 6.5 0 6.5 6.5 0 0 0 0 6.5c0 6.368 6.97 9.885 9.814 11.055.766.315 1.607.315 2.372 0C15.03 16.385 22 12.868 22 6.5A6.5 6.5 0 0 0 15.5 0 6.48 6.48 0 0 0 11 1.81z' fill='%23ef466f'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        opacity: 0
        transition: opacity .2s
    &:hover
        svg
            fill: $neutrals2
    &.active
        svg
            opacity: 0
        &:before
            opacity: 1
    +dark
        background: $neutrals2
        &:hover
            svg
                fill: $neutrals8
