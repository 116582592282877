@import ../../styles/helpers

.section
    +x
        padding-top: 40px
    +m
        padding-top: 32px

.container
    display: flex
    align-items: flex-start
    +t
        display: block

.wrapper
    flex: 0 0 calc(100% - 352px)
    width: calc(100% - 352px)
    padding-right: 64px
    +x
        padding-right: 64px
    +d
        flex: 0 0 calc(100% - 304px)
        width: calc(100% - 304px)
        padding-right: 32px
    +t
        width: 100%
        padding: 0
    &.fullWidth
        width: 100%
        padding-right: 0px
        flex-basis: 100%

.preview
    margin-top: 0px
    flex-shrink: 0
    width: 352px
    +d
        width: 304px
    +t
        display: flex
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        padding: 48px
        background: rgba($neutrals1,.9)
        overflow: auto
        z-index: 999
        visibility: hidden
        opacity: 0
        transition: all .4s
        +dark
            background: rgba($neutrals2,.9)
        &.active
            visibility: visible
            opacity: 1
    +m
        padding: 32px 16px

.top
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 24px
    padding-bottom: 26px
    width: 100%
    border-bottom: 1px solid $neutrals6
    +t
        display: block
        margin-bottom: 32px
        padding-bottom: 0
        border: none
    +m
        margin-bottom: 24px
    +dark
        border-color: $neutrals3

.head
    display: flex
    align-items: center
    margin-bottom: 0px
    +d
        flex-direction: column-reverse
        align-items: flex-start
    +m
        margin-bottom: 2px
    .button
        flex-shrink: 0
        margin-left: 24px
        +d
            margin: 0 0 16px

.title
    margin-right: auto
    font-size: 32px

.list
    margin-bottom: 0px
    padding-bottom: 0px
    border-bottom: 1px solid $neutrals6
    +m
        margin-bottom: 32px
        padding-bottom: 32px
    +dark
        border-color: $neutrals3

.item
    &:not(:last-child)
        margin-bottom: 30px
        +m
            margin-bottom: 32px


.note
    margin-top: 0px
    margin-bottom: 15px
    +caption-2
    color: $neutrals4

.file
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 182px
    margin-top: 16px
    border-radius: 25px
    overflow: hidden
    background: $neutrals7
    +dark
        background: $neutrals2

.load
    position: absolute
    top: 0
    left: 0
    font-size: 400px
    opacity: 0

.icon
    margin-bottom: 3px
    svg
        fill: $neutrals8
.format
    +caption-2
    color: $neutrals4

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

.category
    +body-bold-3
    display: flex
    align-items: flex-end !important
    margin-bottom: 0px

.fieldset
    margin-top: 32px
    .field
        &:not(:last-child)
            margin-bottom: 32px
            +m
                margin-bottom: 20px

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.row
    display: flex
    margin-bottom: 30px
    margin-left: -10px
    margin-right: -4px
    +m
        display: block
        margin: 0
    input
        +body-bold-2

.oneThird
    flex: 0 0 calc(33.333% - 20px)
    width: calc(33.333% - 20px)
.oneHalf
    flex: 0 0 calc(50.5% - 20px)
    width: calc(50.5% - 20px)

.col
    margin: 0 10px
    +m
        width: 100%
        margin: 20px 0
        &:not(:last-child)
            margin-bottom: 20px

.options
    margin-bottom: 40px
    +m
        margin-bottom: 32px

.option
    display: flex
    align-items: flex-start
    &:not(:last-child)
        margin-bottom: 32px

.box
    flex-grow: 1
    +m
        display: none

.switch
    flex-shrink: 0
    margin-left: 24px

.text
    margin-top: 0px
    +caption-1
    color: $neutrals4

.slogan
    margin-top: 0px
    +body-2
    color: $neutrals4
    +m
        margin-bottom: 25px !important

.foot
    display: flex
    align-items: center
    +m
        display: block
        text-align: center
    .button
        +m
            width: 100%
        &:not(:last-child)
            margin-right: 24px
            +m
                margin: 0 0 12px

.saving
    display: inline-flex
    align-items: center
    margin-left: auto
    +button-1

.loader
    margin-left: 24px
