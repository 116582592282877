@import ../../styles/helpers

.info
    +caption-2
    font-weight: 500
    color: $neutrals4

.green
    &:not(:last-child)
        .number
            filter: hue-rotate(315deg)

.green
    &:last-child
        .number
            filter: hue-rotate(185deg) saturate(1.2)

.red
    &:last-child
        .number
            filter: hue-rotate(385deg) saturate(1.2)

.white
    color: $neutrals6

.downer
    margin-top: 16px
    +body-2
    color: $neutrals4

.border
    border-top: 1px solid $neutrals2
    align-items: center
    display: flex
    width: 100%
    justify-content: center
    height: 50px

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-top: 10px
    padding-bottom: 10px
    font-size: 16px
    border-bottom: 1px solid $neutrals2

.col
    +body-2
    &:first-child
        color: $neutrals4
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500

.main
    margin-bottom: 24px
    margin-top: -8px
    +body-2
    color: $neutrals4

.link
    margin-left: 8px
    color: $neutrals2
    transition: color .2s
    svg
        fill: $neutrals2
        transition: fill .2s
    +dark
        color: $neutrals6
        svg
            fill: $neutrals6
    &:hover
        color: $pink
        svg
            fill: $pink

.steps
    flex-shrink: 0
    margin-right: 20px
    // margin-right: auto

.last
    width: 30px
    fill: gray

.last.active
    fill: $pink

.external
    fill: gray
    margin-right: 10px
    margin-left: 10px
    margin-top: -4px

.flexer
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

.step
    position: relative
    display: flex
    align-items: center
    height: 48px
    padding: 0 8px
    border-radius: 24px
    +button-2
    color: $neutrals4
    transition: all .2s
    &:hover
        .external
            fill: $white
    &.next
        color: $neutrals2
        .number
            border-color: gray
        .number.active
            border-color: $pink
    &.active
        background: $neutrals8
        box-shadow: 0px 4px 16px -8px rgba(15, 15, 15, 0.1)
        color: $neutrals2
        .number
            border-color: $pink
            &:after
                opacity: 1
    &:not(:last-child)
        margin-bottom: 24px
        &:after
            content: ""
            position: absolute
            top: 100%
            left: 23px
            height: 24px
            border-left: 2px dashed $neutrals5
    +dark
        &.next
            color: $neutrals8
        &.active
            background: $neutrals2
            color: $neutrals8
            &:not(:last-child)
                &:after
                    border-color: $neutrals4

.number
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    margin-right: 16px
    border: 2px solid $neutrals6
    border-radius: 50%
    transition: all .2s
    +dark
        border-color: $neutrals4
    &.active
        border-color: $pink
    &:after
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 50%
        background: $pink url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cpath d='M4 8l2.667 2.667L12 5.333' stroke='%23fcfcfd' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat 50% 50% / 16px auto
        opacity: 0
        transition: opacity .2s

.wrapper
    // width: 736px
    width: 100%
    padding: 40px
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    border-radius: 25px
    background: $neutrals8
    +d
        flex-grow: 1
        width: auto
        margin-left: 64px
        padding: 32px
    +t
        margin-left: 0
        padding: 40px
    +m
        padding: 0
        box-shadow: none
        background: none
        border-radius: 0
    +dark
        background: #18191D
        +m
            background: none

.clickable
    cursor: pointer
