@import ../../../styles/helpers

.tokenSelector
    display: flex
    overflow: auto
    min-height: 175px

.tokenOption
    border: 2px solid transparent
    border-radius: 15px
    cursor: pointer

.selected
    border: 2px solid $blue

.name
    white-space: nowrap
    text-align: center


.section
    overflow: hidden

.wrapper
    position: relative
    padding-bottom: 0px
    border-bottom: 1px solid #353945
    min-height: 222px

.title
    margin-bottom: 64px
    +m
        margin-bottom: 48px

.inner
    margin: 0 -16px
    +m
        margin: 0 -8px

.card
    width: 100%

.slick-arrow
    top: -54px
