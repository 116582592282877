.badgeContainer 
    box-shadow: 0 0.3rem 0.8rem rgba(7,9,25,.3),0 0.13rem .23rem rgba(7,9,25,.1)
    border-radius: 50%
    display: inline-block
    padding: 10px
    position: relative
    cursor: pointer

.verifiedBadge
    position: absolute
    right: 5%
    bottom: 5%
    border-radius: 50%
    padding: 0.2rem
    background: grey
    svg
        fill: white
    &.verified
        background-image: linear-gradient(135deg,#6b73ff 10%,#d52b82)

.tooltip
    position: relative
    &::before
        content: attr(data-text)
        position: absolute
        bottom: 90%
        transform: translate(-50%, -50%)
        left: 50%
        margin-left: auto
        margin-right: auto
        padding: 0.5rem 1rem
        border-radius: 10px
        background: #000
        color: white
        text-align: center
        opacity: 0
        transition: .3s opacity
        width: max-content
    &::after
        opacity: 0
        top: -5%
        content: ""
        position: absolute
        left: 43%
        border: 10px solid #000
        border-color: black transparent transparent  transparent
        display: block
        transition: .3s opacity
    &:hover
        &::before, &::after
            opacity: 1