@import ../../../styles/helpers

.icon
    margin-bottom: 3px
    svg
        fill: $neutrals8
.format
    +caption-2
    color: $neutrals4

.right
    text-align: right

.bundle
    position: absolute
    top: 15px
    left: 15px
    color: white
    div
        background-color: #c73e77

.category
    +body-bold-2
    display: flex
    align-items: flex-end !important
    height: 40px
    justify-content: center

.inner
    padding: 48px
    background: $neutrals8
    box-shadow: 0px 64px 64px rgba(31, 47, 70, 0.12)
    padding-top: 20px
    padding-bottom: 20px
    border-radius: 25px
    margin-top: 24px
    +d
        padding: 24px
    +t
        position: relative
        width: 352px
        margin: auto
    +dark
        background: $neutrals1
        box-shadow: inset 0 0 0 1px $neutrals3

.close
    display: none
    position: absolute
    top: 20px
    right: 24px
    width: 40px
    height: 40px
    background: $neutrals8
    border: 2px solid $neutrals6
    border-radius: 50%
    font-size: 0
    +t
        display: inline-block
    svg
        fill: $neutrals2
        transition: transform .2s
    +dark
        border-color: $neutrals3
        background: $neutrals1
        svg
            fill: $neutrals8

.info
    margin-bottom: 24px
    +body-bold-1

.preview
    position: relative
    border-radius: 25px
    overflow: hidden
    & > img
        width: 100%

.link
    padding: 20px 0
    color: $neutrals2
    +dark
        color: $neutrals8

.line
    display: flex
    align-items: flex-start
    &:first-child
        margin-bottom: 12px

.title
    margin-right: auto
    padding-top: 1px
    +body-bold-2

.text
    margin-top: 0px
    +caption-2
    color: $neutrals4
    display: flex
    justify-content: center

.counter
    margin-top: 2px
    +dark
        color: $neutrals6

.users
    display: flex
    margin-right: auto

.avatar
    display: flex
    flex-shrink: 0
    width: 28px
    height: 28px
    border-radius: 50%
    border: 2px solid $neutrals8
    div
        margin-left: 10px
    +dark
        border-color: $neutrals2
    img
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%
    &:not(:first-child)
        margin-left: -8px

.foot
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 12px
    padding-top: 12px
    border-top: 1px solid $neutrals6
    +caption-1
    color: $neutrals4
    +dark
        border-color: $neutrals3

.status
    svg
        margin: -2px 4px 0 0
        fill: $neutrals4
    span
        font-weight: 600
        color: $neutrals3
        +dark
            color: $neutrals8

.clear
    display: inline-flex
    align-items: center
    margin-top: 24px
    +button-1
    color: $neutrals4
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals4
        transition: fill .2s
    &:hover
        color: $blue
        svg
            fill: $blue

.imgPlaceholder
    background: rgba(255,255,255,0.1)
    // border: 1px solid lightgrey
    height: 260px
    width: 255px
    border-radius: 15px
