@import ../../../styles/helpers

.container
    +m
        position: relative

.section
    overflow: hidden
    padding-bottom: 130px
    +x
        padding-bottom: 100px
    +m
        padding-bottom: 64px

.top
    display: flex
    width: 100%
    justify-content: space-between
    align-items: center
    margin-bottom: 24px
    +m
        display: block
        margin-bottom: 32px

.stageRight
    text-align: right
    +body-bold-1
    color: $neutrals4
    +m
        text-align: left

.stage
    text-align: left
    +body-bold-1
    color: $neutrals4

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.field
    .dropdown
        min-width: 256px
        +m
            min-width: 100%

.box
    margin-right: 20px
    +m
        margin: 0 0 16px
    .dropdown
        min-width: auto
        margin-left: -24px
        +m
            display: inline-block

.wrapper
    margin: 0 -16px
    +m
        margin: 0 -4px

.item
    padding: 24px
    border-radius: 25px
    background: $neutrals8
    transition: box-shadow .2s
    &:hover
        cursor: pointer
    +dark
        background: $neutrals1

.custom
    transition: all 0.4s
    box-shadow: 0px 0px 1px 1px #141416
    &:hover
        box-shadow: 0px 0px 1px 1px $purple

.instant
    transition: all 0.4s
    box-shadow: 0px 0px 1px 1px #141416
    &:hover
        box-shadow: 0px 0px 1px 1px $pink

.head
    display: flex
    align-items: center
    margin-bottom: 24px
    padding-bottom: 24px
    border-bottom: 1px solid $neutrals6
    justify-content: space-between
    +dark
        border-color: $neutrals3

.rating
    display: flex
    align-items: center
    height: 24px
    margin-right: auto
    padding: 0 8px
    border-radius: 12px
    +caption-bold-2
    color: $neutrals8
    padding-right: 10px

.icon
    margin-right: 4px
    img
        width: 16px

.control
    display: flex

.tokenLogo
    width: 30px
    opacity: 70%
    &:hover
        opacity: 100%


.button
    svg
        fill: $neutrals5
        transition: fill .2s
    &:hover
        svg
            fill: $blue
    &:not(:last-child)
        margin-right: 8px

.body
    text-align: center

.avatar
    position: relative
    width: 90px
    margin: 0 auto 16px
    transition: width .2s
    & > img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        object-fit: cover
        border-radius: 50%
    &:before
        content: ""
        display: block
        padding-bottom: 100%

.reward
    position: absolute
    right: -4px
    bottom: -4px

.name
    margin-bottom: 2px
    font-weight: 500
    overflow: hidden
    backface-visibility: hidden
    height: 25px
    white-space: nowrap
    text-overflow: ellipsis
    width: 200px

.price
    +caption-2
    color: $neutrals4
    span
        font-weight: 600
        color: $neutrals2
        +dark
            color: $neutrals6

.loader
    width: 100%
    height: 95px
    display: flex
    justify-content: center
    background-image: url("../../../assets/images/loaders/golden.svg")
    background-repeat: no-repeat
    background-size: 30px
    background-position: center
    filter: hue-rotate(300deg)
    align-items: center
