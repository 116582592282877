@import ../../styles/helpers

.top
    display: flex
    align-items: center
    justify-content: space-between
    padding-bottom: 26px
    border-bottom: 1px solid $neutrals6
    +t
        display: block
        margin-bottom: 32px
        padding-bottom: 0
        border: none
    +m
        margin-bottom: 24px
    +dark
        border-color: $neutrals3

.section
    padding-top: 32px
    padding-bottom: 0px

.section-pt40
    padding-top: 32px

.text
    +body-2
    color: $neutrals4

.search
    position: relative
    flex-shrink: 0
    width: 320px
    margin-left: 30px
    +t
        width: 100%
        margin: 0

.input
    width: 100%
    height: 48px
    padding: 0 48px 0 14px
    border-radius: 12px
    background: none
    border: 2px solid $neutrals6
    +poppins
    +caption-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    +placeholder
        color: $neutrals4
    &:focus
        border-color: $neutrals4

.placeholder
    visibility: hidden

.result
    position: absolute
    top: 8px
    right: 8px
    bottom: 8px
    width: 32px
    height: 32px
    border-radius: 50%
    background: $blue
    transition: background .2s
    svg
        fill: $neutrals8
    &:hover
        background: darken($blue, 10)

.sorting
    display: flex
    align-items: center
    +t
        display: block
    +m
        margin-bottom: 32px
    .dropdown
        width: 256px
        margin-right: auto
        +t
            width: 100%
            margin: 0 0 32px

.nav
    display: flex
    +m
        margin: 0 -32px
        overflow: auto
        overflow-x: auto
        -ms-overflow-style: none
        scrollbar-width: none
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            display: none
        &:before,
        &:after
            content: ""
            flex-shrink: 0
            width: 32px
            height: 1px

.link
    flex-shrink: 0
    margin: 0 6px
    padding: 6px 12px
    border-radius: 14px
    background: none
    +button-2
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2
    &:not(:last-child)
        +m
            margin-right: 12px

.row
    display: flex
    +t
        display: block

.wrapper
    flex-grow: 1
    padding-left: 12px
    +t
        padding-left: 0
    &.fullWidth
        padding-left: 0px
        .card
            height: 500px
            +r(1279)
                height: 640px

.filters
    margin-top: 42px
    flex-shrink: 0
    width: 220px
    margin-bottom: 32px
    +t
        width: 100%
        margin-bottom: 42px

.range
    margin-bottom: 24px

.scale
    display: flex
    justify-content: space-between
    font-weight: 500

.group
    margin-bottom: 24px
    padding-bottom: 24px
    border-width: 1px 0
    border-style: solid
    border-color: $neutrals6
    border-top-style: none
    +dark
        border-color: $neutrals3

.item
    &:not(:last-child)
        margin-bottom: 24px

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5

.rotate
    display: inline-flex
    align-items: center
    +button-2
    margin-left: 15px
    cursor: pointer
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals2
        transform: rotate(45deg)
        transition: fill .2s
        +dark
            fill: $neutrals8
    &:hover
        color: $blue
        svg
            fill: $blue

.reset
    align-items: center
    +button-2
    cursor: pointer
    transition: color .2s
    svg
        margin-right: 8px
        fill: $neutrals2
        transition: fill .2s
        +dark
            fill: $neutrals8
    &:hover
        color: $blue
        svg
            fill: $blue

.list
    height: 100vh
    margin-top: 35px
    position: relative
    display: flex
    flex-wrap: wrap
    +m
        display: block
        margin: 0

@for $i from 1 through 50
    .list.reverse > div:nth-child(n + #{$i})
        order: 50 - $i

.card
    flex: 0 0 calc(33.333% - 24px)
    max-width: calc(33.333% - 24px)
    margin: 0px 12px 0
    padding: 12px
    box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12)
    border-radius: 20px
    margin-bottom: 27px
    // width: 100%
    height: 440px
    +r(1279)
        flex: 0 0 calc(50% - 32px)
        max-width: calc(50% - 32px)
        height: 640px
    +m
        max-width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px
    +dark
        background: $neutrals2


.card.hidden
    display: none

.head
    display: flex
    align-items: center
    margin-bottom: 0px
    +d
        flex-direction: column-reverse
        align-items: flex-start
    +m
        margin-bottom: 2px
    .button
        flex-shrink: 0
        margin-left: 24px
        +d
            margin: 0 0 16px

.title
    margin-right: auto
    font-size: 32px

.btns
    margin-top: 32px
    text-align: center
    button
        margin-left: 5px
        margin-right: 5px

.checkbox
    &:not(:last-child)
        margin-bottom: 12px

.loader 
    width: 100%
    height: 250px
    display: flex
    justify-content: center
    align-items: center