@import ../../styles/helpers

.link
    flex-shrink: 0
    margin: 0 6px
    border-radius: 14px
    background: none
    +button-1
    font-weight: normal
    color: $neutrals4
    transition: all .2s
    +m
        margin: 0
    &:hover
        color: $neutrals3
        +dark
            color: $neutrals6
    &.active
        background: $neutrals3
        color: $neutrals8
        +dark
            background: $neutrals8
            color: $neutrals2
    &:not(:last-child)
        +m
            margin-right: 12px
