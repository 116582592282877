@import ../../styles/helpers

.list
    display: flex
    flex-wrap: wrap
    height: 110vh
    align-content: baseline
    // margin: -32px -16px 0
    // margin-top: -32px
    +x
        // margin: -32px -10px 0
        margin: -32px 0px 0
    +d
        // margin: -32px -16px 0
        margin: -32px 0px 0
    +m
        display: block
        margin: 0

.poolViewList
    +m
        display: flex
        justify-content: space-between
        padding: 5% 0
        // padding-top: 0px
        flex: 0 0 calc(50% - 32px)

.profileViewList
    width: calc(90%)
    +m
        width: 100%

    .loaderContainer
        justify-content: center
        align-items: center
        display: flex
        flex-direction: column-reverse
        width: 100%
        margin-top: 57px
        margin-bottom: 60px

.card
    flex: 0 0 calc(24.55% - 27px)
    width: calc(24.55% - 27px)
    margin: 32px 12px 0
    padding-bottom: 32px
    margin-top: 0px
    overflow: hidden
    +x
        flex: 0 0 calc(24.55% - 20px)
        width: calc(24.55% - 20px)
        margin: 32px 10px 0
    +m
        flex: 0 0 calc(50% - 32px)
        width: 100%
        margin: 0 15px
        &:not(:last-child)
            margin-bottom: 32px
    &.profile
        width: 100%

.loader
    justify-content: center
    align-items: center
    display: flex
    flex-direction: column-reverse
    margin: 48px auto 10px

.scrollIndicators
    // background: linear-gradient($neutrals1 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), $neutrals1 70%) 0 100%, radial-gradient(farthest-side at 50% 0, $pink, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, $pink, rgba(0, 0, 0, 0)) 0 100%
    background-repeat: no-repeat
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px
    background-attachment: local, local, scroll, scroll
    // padding-top: 25px

.tableView
    +t
        padding-top: 20px
    +m
        padding-top: 0
        .poolViewList
            padding: 0

