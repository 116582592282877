@import ../../../styles/helpers

.user
    position: relative
    +m
        position: static

.head
    position: relative
    display: flex
    align-items: center
    padding: 4px 16px 4px 4px
    border-radius: 20px
    box-shadow: inset 0 0 0 2px $neutrals6
    +button-2
    cursor: pointer
    transition: box-shadow .2s
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    +dark
        box-shadow: inset 0 0 0 2px $neutrals3
    +m
        padding: 0
        border-radius: 0
        box-shadow: none
    +dark
        box-shadow: inset 0 0 0 2px $neutrals3
        +m
            box-shadow: none
    &:hover
        box-shadow: inset 0 0 0 2px $blue
        +m
            box-shadow: none

.avatar
    flex-shrink: 0
    width: 32px
    height: 32px
    img
        width: 100%
        height: 100%
        border-radius: 50%

.wallet
    margin-left: 12px
    +m
        display: none

.currency
    color: $green

.body
    position: absolute
    top: calc(100% + 20px)
    left: 50%
    width: 256px
    margin-left: -128px
    padding: 32px 16px 20px
    box-shadow: 0px 16px 64px 0 rgba(31, 47, 70, 0.4)
    border-radius: 12px
    background: $neutrals8
    opacity: 0
    transform: scale(.9)
    transform-origin: 50% 50px
    will-change: transform
    animation: showUser .4s forwards
    +t
        left: auto
        right: 0
    +m
        left: 16px
        right: 16px
        top: calc(100% + 16px)
        width: auto
        margin: 0
    &:before
        content: ""
        position: absolute
        left: 50%
        bottom: 100%
        width: 32px
        height: 13px
        transform: translateX(-50%)
        background: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%23FCFCFD'/%3E%3C/svg%3E%0A") no-repeat 100% 50% / 100% auto
        +m
            display: none
    +dark
        background: $neutrals2
        &:before
            background-image: url("data:image/svg+xml,%3Csvg width='32' height='13' viewBox='0 0 32 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3432 2.65685L0 13H32L21.6569 2.65686C18.5327 -0.467339 13.4673 -0.467344 10.3432 2.65685Z' fill='%2323262F'/%3E%3C/svg%3E%0A")

@keyframes showUser
    0%
        opacity: 0
        transform: scale(.9)
    100%
        opacity: 1
        transform: scale(1)

.name
    +body-bold-1

.code
    display: flex
    align-items: center
    margin-bottom: 8px

.number
    font-weight: 500
    color: $neutrals4

.copy
    margin-left: 8px
    svg
        fill: $blue
        transition: fill .2s
    &:hover
        svg
            fill: darken($blue, 10)

.wrap
    margin-bottom: 7px
    padding: 8px
    border-radius: 25px
    box-shadow: 0px 24px 24px -8px rgba(15, 15, 15, 0.2)

.line
    display: flex
    align-items: center
    margin-bottom: 10px

.wise
    background: #1d1d21
    padding: 4px

.preview
    flex-shrink: 0
    width: 40px
    height: 40px
    margin-right: 16px
    img
        width: 100%
        height: 100%
        border-radius: 50%

.info
    +caption-2
    color: $neutrals4

.price
    +body-bold-1

.button
    width: 100%
    height: 32px
    padding: 0 10px
    font-size: 14px

.item
    display: flex
    align-items: center
    padding: 17px 0 12px
    +button-2
    color: $neutrals4
    &:not(:last-child)
        border-bottom: 1px solid $neutrals6
        +dark
            border-color: $neutrals3
    &:hover
        cursor: pointer
        color: $blue
        svg
            fill: $blue

.icon
    flex-shrink: 0
    width: 20px
    margin-right: 8px
    svg
        fill: $neutrals4
        transition: fill .2s

.text
    margin-right: auto

.hideBannerSwitch 
    pointer-events: none