@import ../../../styles/helpers

.item
    border-top: 1px solid $neutrals6
    +dark
        border-color: $neutrals3
    &.active
        .head
            &:before
                transform: translateY(-50%) rotate(180deg)
        .body
            display: block

.box
    position: relative
    padding-left: 26px
    font-weight: 600

.green
    &:before
        content: ""
        position: absolute
        top: 2px
        left: 0
        width: 16px
        height: 16px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath fill-rule='evenodd' d='M8.999 17.333c4.602 0 8.333-3.731 8.333-8.333S13.602.667 8.999.667.666 4.398.666 9s3.731 8.333 8.333 8.333zm4.874-10.126a1 1 0 0 0-1.414-1.414l-4.293 4.293-1.793-1.793a1 1 0 1 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l5-5z' fill='%2345b36b'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    &:not(:last-child)
        // margin-bottom: 8px

.orange
    &:before
        content: ""
        position: absolute
        top: 2px
        left: 0
        width: 16px
        height: 16px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath fill-rule='evenodd' d='M8.999 17.333c4.602 0 8.333-3.731 8.333-8.333S13.602.667 8.999.667.666 4.398.666 9s3.731 8.333 8.333 8.333zm4.874-10.126a1 1 0 0 0-1.414-1.414l-4.293 4.293-1.793-1.793a1 1 0 1 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l5-5z' fill='orange'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
    &:not(:last-child)
        // margin-bottom: 8px


.head
    position: relative
    padding: 18px 40px 18px 0
    +body-bold-2
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    transition: color .2s
    display: flex
    align-items: center
    &:before
        content: ""
        position: absolute
        top: 50%
        right: 8px
        width: 10px
        height: 6px
        transform: translateY(-50%)
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
        transition: transform .2s
    &:hover
        color: $blue
    .headerLoader
        margin-left: auto

.body
    display: none
    padding-bottom: 32px
    +body-2
    color: $neutrals4

.button
    margin-top: 24px
    &:not(:first-child)
        margin-left: 5px

.flexRow
    display: flex
    justify-content: space-between
    align-items: center
    width: 100%
    +t
        height: 25px
    &:not(:first-child)
        margin-top: 10px

.buttonGroup
    display: flex
    flex-direction: row
    align-items: baseline
    justify-content: space-between

.tooltip
    display: flex
    align-items: center
    align-content: center
    justify-content: center
    text-align: center
    border-radius: 50%
    margin-left: 5px
    margin-top: 2px
    width: 3px
    height: 3px
    padding: 8px
    border: 2px solid $neutrals4
    color: $neutrals4
    float: right

.smaller
    width: 2px
    height: 2px
    padding: 8px
    font-size: 12px
    margin-left: 6px

a
    svg
        fill: $neutrals4
        padding-bottom: 2px
    &:hover
        svg
            fill: $neutrals6

.icon
    margin-left: 5px
    font-weight: bold
    font-size: 20px

.main
    margin: 5px 5px 16px
    +body-2
    color: $neutrals4

.subTitle
    margin-top: 10px
    +body-2
    color: $neutrals4

.source
    display: flex
    justify-content: center
    margin-bottom: 0px

.loader
    display: flex
    justify-content: center
    align-items: center
    height: 200px

.title,
.field,
.checkbox
    margin-top: 16px

.label
    margin-bottom: 12px
    +hairline-2
    color: $neutrals5
