@import ../../../styles/helpers

.hero
    position: relative
    display: flex
    align-items: center
    min-height: 350px
    padding: 0px 0 70px
    +d
        min-height: 606px
        padding: 80px 0
    +m
        min-height: auto
        padding: 64px 0

.wrap
    position: relative
    z-index: 3
    margin-top: 50px
    max-width: 500px
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 32px
        margin-top: 0px

.title
    margin-top: 50px
    margin-bottom: 20px
    text-transform: initial
    +m
        margin-top: 0px
        text-align: center

.text
    margin-bottom: 40px
    +body-1
    color: $neutrals4

.btns
    display: flex
    margin-bottom: 16px
    +m
        flex-direction: column
        gap: 16px

.button
    +m
        padding: 0 22px
    &:not(:last-child)
        margin-right: 16px
        +m
            margin-right: unset

.scroll
    position: absolute
    bottom: 96px
    +d
        display: none

.gallery
    position: absolute
    top: 46.6%
    right: calc(50% - 720px)
    width: 800px
    transform: translateY(-50%)
    pointer-events: none
    +d
        top: 54%
        right: calc(50% - 600px)
        width: 600px
    +t
        right: calc(50% - 520px)
        width: 550px
    +m
        position: relative
        top: auto
        right: auto
        width: 580px
        margin: 0 auto
        transform: translateY(0)
        display: none
    +s
        left: -32%

.pool
    width: 100%
    height: 100%
    position: absolute
    top: 0px
    // background: url(https://cdn.pixabay.com/photo/2022/01/30/13/17/mystical-6980870_1280.jpg)
    background: url(bg.jpeg)
    // background: url(owl-forest.jpeg)
    // background: url(https://cdn.pixabay.com/photo/2022/04/14/13/54/nature-7132530_1280.jpg)
    // background: url(https://i.imgur.com/uWpY8Ir.png)
    // background: url(https://uigstudio.com/uploads/media/1920x1080/05/1715-metaverse-virtual-real-estate-investment-potential.jpg?v=1-0)
    background-size: cover
    // first
    // filter: brightness(0.1) contrast(0.9) blur(0px) grayscale(0.0)
    // second
    // filter: brightness(0.12) contrast(0.88) blur(0px) grayscale(0.0) // forest
    filter: brightness(0.1) contrast(0.9) blur(0px) grayscale(0.0)

.preview
    &:first-child
        position: relative
        z-index: 2
        width: 80%
        transform: scale(1.05)
        margin-top: 95px
    &:nth-child(2)
        top: 7%
        right: 5%
        z-index: 3
        width: 40%
    &:nth-child(3)
        top: 50%
        left: 0%
        z-index: 3
        width: 35%
    &:not(:first-child)
        position: absolute
    img
        width: 100%

.buttonWrapper
    display: inline-block
    +t
        width: 100%

.buttonParent
    display: flex
    flex-direction: column
