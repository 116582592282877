@import ../../styles/helpers

.section
    overflow: hidden
    padding-top: 0px

.wrapper
    position: relative

.stage
    +body-bold-1
    color: $neutrals4

.title
    margin-bottom: 32px
    +m
        margin-bottom: 24px

.inner
    margin: 0 -16px
    +m
        margin: 0 -8px

.card
    width: 100%

.loader 
    width: 100%
    height: 250px
    display: flex
    justify-content: center
    align-items: center
