@import ../../styles/helpers

.title
    margin-bottom: 32px

.text
    margin-bottom: 16px
    +body-2
    color: $neutrals4

.loaderSmall
    margin-right: 20px

.inactive
    cursor: default
    filter: grayscale(1)
    color: gray

.info
    margin-bottom: 3px
    +body-bold-1

.btns
    margin-top: 32px

.button
    width: 100%
    &:not(:last-child)
        margin-bottom: 8px
