@import ../../styles/helpers

.treeLabel 
    color: $neutrals8
    &::after
        content: ":"
.treeValue
    color: $neutrals4
    padding-left: 5px

.increase
    color: $green

.decrease
    color: $pink

.arrowIndicator
    color: $orange
