@import ../../styles/helpers

.title
    margin-bottom: 32px

.main
    margin-bottom: 16px
    +body-2
    color: $neutrals4

.row
    display: flex
    justify-content: space-between
    width: 100%
    padding-right: 5px
    padding-left: 5px
    padding-top: 5px
    padding-bottom: 10px
    font-size: 16px

.columned
    flex-direction: column
    gap: 30px

.arrows
    white-space: nowrap

.col
    +body-2
    &:nth-child(2)
        margin-left: auto
        padding-left: 30px
        font-weight: 500

.inputContainer
    // width: 45%
    .row
        border-bottom: 1px solid $neutrals2

.rowDivider
    border-bottom: 1px solid $neutrals2

.label
    color: $neutrals4

.input
    text-align: right
    -moz-appearance: textfield
    padding: 0 5px
    width: 100%
    background: none
    font-size: 16px
    line-height: 1.5
    +poppins
    color: $neutrals2
    transition: border-color .2s
    +dark
        color: $neutrals8
    +placeholder
        color: $neutrals4

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

input[type=number]
    -moz-appearance: textfield

.swapCurrency,
.buttonIcon
    fill: $neutrals2
    +dark
        fill: $neutrals8

.swapCurrency
    display: flex
    align-items: center
    justify-content: flex-start
    cursor: pointer
    span
        margin-left: 10px
    &:not(.disabled)
        &:hover
            fill: $pink
            span
                color: $pink


.button
    width: 100%
    margin-top: 30px
    &:not(:last-child)
        margin-bottom: 8px

.loaderSmall
    margin-right: 20px

.option
    width: 20%
